import React, {useState} from "react";
import QuestionAnswer from "./QuestionAnswer";
import css from "./questionsAnswers.module.scss";
import BoxHeader from "../BoxHeader/BoxHeader";

const QuestionsAnswers = props => {
  const { sourceItems = [], translationsObject = '', color } = props;

  const [items, setItems] = useState(sourceItems);

  const toggle = index => {
    const item = items[index];
    const nextItem = { ...item, open: !item.open };
    const nextItems = [ ...items ];
    nextItems[index] = nextItem;
    setItems(nextItems);
  };

  const renderQuestionsAnswers = () => {
    return items.map((item, index) => {
      const { name, answer, question } = item;
      const answer3 = answer || answer;
      return (
        <QuestionAnswer
          key={name}
          question={question} 
          answer={answer3}
          open={item.open}
          onToggle={() => toggle(index)}
        />
      )
    });
  };

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <div className={css.contentInner}>
          <BoxHeader style={{marginBottom: '80px'}} lineColor={color} contentAligned={false}>
            FAQ
          </BoxHeader>
          {renderQuestionsAnswers()}
        </div>
      </div>
    </div>
  )
};

export default QuestionsAnswers;

