import React from "react";
import css from './questionAnswer.module.scss';
import IconMore from '../../../images/UserGuide/questionsAnswers/chevron-down.svg';
import IconLess from '../../../images/UserGuide/questionsAnswers/chevron-up.svg';

const QuestionAnswer = props => {
  const { question = '', answer = '', open = false, onToggle = () => {} } = props;

  const openClass = open ? css.open : '';

  return (
    <div className={css.wrapper}>
      <div className={css.header} data-open={open}>
        <div className={css.question}>{question}</div>
        <button className={css.button} onClick={() => onToggle()}>
          <img src={open ? IconLess : IconMore } alt="" />
        </button>
      </div>
      <div className={[css.answer, openClass].join(' ')}>
        <div className={css.answerInner}>{answer}</div>
      </div>
    </div>
  )
};

export default QuestionAnswer;
