import React from "react";
import css from './supportBox.module.scss';
import BoxHeader from "../BoxHeader/BoxHeader";

const SupportBox = props => {
  const { title, mascot, textLeft, textRight, background, color } = props;

  return (
    <div className={css.wrapper}>
      <div className={css.background}>
        {background}
      </div>
      <div className={css.wrapperInner}>
        <div className={css.header}>
          <BoxHeader style={{marginBottom: '24px'}} lineColor={color} contentAligned={false}>
            {title}
          </BoxHeader>
          <div className={css.mascot}>
            {mascot}
          </div>
        </div>
        <div className={css.text}>
          <div className={css.column}>
            {textLeft}
          </div>
          <div className={css.column}>
            {textRight}
          </div>
        </div>
      </div>
    </div>
  )
};

export default SupportBox;
