import React from "react";

const ExternalLink = props => {
  const { url, content, color = 'mainRed' } = props;

  const getColorStyle = () => {
    const color = !!props.color ? props.color : 'mainRed';
    switch (color) {
      case 'green':
        return 'var(--mainGreenColor)';
      default:
        return 'var(--mainRedColor)';
    }
  };

  return (
    <a href={url} target="_blank" style={{ color: getColorStyle() }}>{content}</a>
  )
};

export default ExternalLink;
