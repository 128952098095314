import React from "react";
// import css from './supportDescriptionOptiplus3.module.scss';
import {useTranslation} from "react-i18next";
import SupportBox from "../../../../shared/SupportBox/SupportBox";

const SupportDescriptionOptiplus3 = () => {
  const { t } = useTranslation();
  const renderTextRight = () => {
    return (
      <div>
        <p>
          <strong>File sizes: </strong> <br/>- Up to 4K (3840px x 2160px) resolution via web interface <br/>- Up to 8K (7680px x 4320px) resolution via API
        </p>
          <p><strong>Access type: </strong> <br/>- Web interface <br/>- API (documentation, examples, packages are available out of the box for all registered users)</p>
      </div>
    )
  };

  return (
    <SupportBox
      title={<><app-red>T</app-red>echnical <app-red>R</app-red>equirements</>}
      textLeft={<><p><strong>Supported file types:</strong> <br/>Input/output file types: jpg, jpeg, png, tif, tiff, webp.</p> <p><strong>Supported color spaces: </strong> <br/>CMYK, RGB, RGBA as input, RGB, RGBA as output.</p></>}
      textRight={renderTextRight()}
      color={'green'}
    />
  )
};

export default SupportDescriptionOptiplus3;
