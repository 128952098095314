import React from "react";
import css from './supportDescriptionOptiplus2.module.scss';
import {useTranslation} from "react-i18next";
import SecurityImg from "../../../../../images/UserGuide/security.svg";
import SupportBox from "../../../../shared/SupportBox/SupportBox";
import {getLink, getRoutes} from "../../../../shared/routes";
import {Link} from "gatsby";
import CompanySupportMail from "../../../../shared/CompanySupportMail/CompanySupportMail";

const SupportDescriptionOptiplus2 = () => {
  const { t } = useTranslation();
  const renderSubpageLink = (subpage) => {
    const link = getLink(subpage);
    return <Link to={link}><span className={css.greenLink}>Legal Documents</span></Link>
  };

  const renderTextRight = () => {
    return (
      <div>
        <p>
          <strong>Privacy and security rules:</strong><br/>
          {renderSubpageLink([getRoutes().LegalDocuments])}
        </p>
        <p>
            <strong>Specified technical security questions:</strong> <br/>
            Please reach out to us by e-mail{" "}
          <CompanySupportMail subject={"Specified technical security question"} color={'green'} />
        </p>
      </div>
    )
  };

  return (
    <SupportBox
      title={<><app-red>S</app-red>ecurity</>}
      textLeft={<><p><strong>Image processing security:</strong> <br/>- all received photos are treated confidentially and properly secured <br/>- they are not shared, not published and not used in any other way than <app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak> is allowed to <br/>- all file transfers are properly secured by using encrypted protocols (SSL, TLS), methods, secured keys and tokens <br/>- user files are stored on properly secured <app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak> cloud services</p></>}
      textRight={renderTextRight()}
      mascot={<img src={SecurityImg} className={css.mascot} alt={'opti optiwai security'} />}
      color={'green'}
    />
  )
};

export default SupportDescriptionOptiplus2;
