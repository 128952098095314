import React from 'react';

import {getLink, getRoutes} from '../../shared/routes';
import css from './card.module.scss';
import {Link} from "gatsby";
import Arrow from "../../../images/icons/long-arrow-pointing-to-the-right.svg";
import ExternalLink from "../ExternalLink/ExternalLink";

const Card = (props) => {
  const {
    smallTitle = false,
    logo,
    logoWrapperHeight = 75,
    image,
    title,
    text,
    textFooter,
    linkTo ,
    externalLinkTo,
    style = {},
    color
  } = props;
  const hasLogo = !!logo;
  const hasImage = !hasLogo && !!image;
  const smallTitleClass = smallTitle ? css.smallTitle : '';
  const wrapperHasImageClass = hasImage ? css.hasImage : '';

  const getBackgroundColor = () => {
    const propsColor = !!color ? color : 'mainRed';
    switch (propsColor) {
      case 'green':
        return 'var(--mainGreenColor)';
      default:
        return 'var(--mainRedColor)';
    }
  };

  const renderLink = () => {
    const content = (
      <div className={css.link}>
          Learn more
      </div>
    );
    if (externalLinkTo) {
      return <ExternalLink url={externalLinkTo} content={content}/>;
    }
    const link = getLink(linkTo);
    const isAnchor = link.startsWith("#");
    if (isAnchor) {
      return (<a href={link}>{content}</a>);
    }
    return (<Link to={link}>{content}</Link>);
  };

  return (
    <div
      className={[css.wrapper, wrapperHasImageClass].join(' ')}
      style={style}
    >
      <div className={css.background} />

      <div className={css.wrapperInner}>

        {hasLogo &&
          <div className={css.logoWrapper}>
            <div className={css.logo} style={{ height: logoWrapperHeight }} >
              {logo}
            </div>
          </div>
        }

        {hasImage &&
        <div className={[css.imageWrapper].join(' ')}>
          <div className={css.imageWrapperInner}>{image}</div>
        </div>
        }

        <div className={[css.header, smallTitleClass].join(' ')}>
          {title}
        </div>

        <div className={css.textWrapper}>
          {text}
          {textFooter &&
          <div className={css.textFooter}>
            {textFooter}
          </div>}
        </div>

        <div className={css.linkWrapper} style={{ backgroundColor: getBackgroundColor() }}>
          {renderLink()}
        </div>

      </div>

    </div>
  )
};

export default Card;

