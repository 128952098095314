import React from "react";
import css from './howToOptiplus.module.scss';
import {useTranslation} from "react-i18next";
import BoxHeader from "../../../../shared/BoxHeader/BoxHeader";
import Card from "../../../../shared/Card/Card";
import CardContainer from "../../../../shared/CardContainer/CardContainer";
import howToOptiplusSvgPath from "../../../../../images/logos/optiwaiplus_w_outline_logo.svg";

const HowToOptiplus = () => {
  const { t } = useTranslation();

  const cardStyle = { width: '236px', height: '250px', margin: '0 10px 32px' };

  const renderTitle = title => {
    return (
      <div className={css.cardTitle}>
        {title}
      </div>
    )
  };

  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <BoxHeader lineColor={'green'}>How To</BoxHeader>
      </div>
      <div className={css.cards}>
        <CardContainer>
           <Card
            logo={<img src={howToOptiplusSvgPath} alt={'how to quick start guide optiwaiplus thumbnail'}/>}
            title={renderTitle(<><strong><app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak></strong> quick start guide</>)}
            externalLinkTo={"https://www.youtube.com/watch?v=jX7KezNscIE"}
            style={cardStyle}
            color={'green'}
          />

        </CardContainer>
      </div>
    </div>
  )
};

export default HowToOptiplus;
