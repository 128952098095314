import React from "react";
import css from './supportDescriptionOptiplus1.module.scss';
import {useTranslation} from "react-i18next";
import WhatIsImg from "../../../../../images/UserGuide/what_is_optiwaiplus.svg";
import SupportBox from "../../../../shared/SupportBox/SupportBox";

const SupportDescriptionOptiplus1 = () => {
  const { t } = useTranslation();
  return (
    <SupportBox
      title={<><app-red>W</app-red>hat is <app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak>?</>}
      textLeft={<><p><app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak> is a fully automated processing engine, powered by AI technology, designed to automatically enhance and edit Photos.</p><p>Editing photo reconstruction are operations of pixels restoration powering the AI trained on the neural network machine leaning  to perform the required professional quality level.</p><p><strong>Editing - final result is a  manipulated reconstruction of a photo.</strong></p></>}
      textRight={<><p>Optimisation process is a combination of widely used standard enhancement operations like: Color Balance, Brightness/Contrast, Hue/Saturation, Curves, Levels, Exposure, Vibrance, Selective Color, Threshold extended with cutting edge AI algorithms and technologies providing <strong>Dynamic Range Correction; Image Inpainting; Multicultural aware natural skin tones correction; Progressive shadows and highlights correction; Professional colors balance management; Specialised correction for low quality photos</strong> and many more.</p><p><strong>Optimisation - final result is just a visual improvement, without changing or modifying true context of the photo.</strong></p></>}
      mascot={<img src={WhatIsImg} className={css.mascot} alt={'opti what is optiwai plus'} />}
      color={'green'}
    />
  )
};

export default SupportDescriptionOptiplus1;
