import React from 'react';
import css from './cardContainer.module.scss';
import BoxHeader from "../BoxHeader/BoxHeader";

const CardContainer = (props) => {
  const { title = '', background = null } = props;

  return (
    <div className={css.wrapper}>

      {background}

      <div className={css.wrapperInner}>

        {title &&
        <BoxHeader style={{ marginBottom: '50px' }}>{title}</BoxHeader>
        }

        <div className={css.cards}>
          {props.children}
        </div>

      </div>
    </div>
  );
};

export default CardContainer;
