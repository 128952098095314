import React from 'react';
// import css from './faqOptiplus.module.scss';
import QuestionsAnswers from "../../../../shared/questionsAnswers/QuestionsAnswers";
// import {Trans} from "react-i18next";
import {getLink} from "../../../../shared/routes";
import CompanySalesMail from "../../../../shared/CompanySalesMail/CompanySalesMail";
import CompanyContactMail from "../../../../shared/CompanyContactMail/CompanyContactMail";
import CompanySupportMail from "../../../../shared/CompanySupportMail/CompanySupportMail";

const FaqOptiplus = () => {
  const openOnLoad = false;

  // name - the key from translations
  const sourceItems = [
    {
      name: "questionAnswer01",
      open: openOnLoad,
      answer: (<p>No matter you use <app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak> Web interface or dedicated API the process is very efficient.<br/>It looks like: <strong>Uploading photos -> AI processing -> Downloading results</strong>.<br/>Simple as that.</p>),
      question: (<p>How does it work?</p>)
    },
    {
      name: "questionAnswer06",
      open: openOnLoad,
      answer: (<p>Yes, it does. All images are optimised for digital usage with applied standard <strong>sRGB IEC61966-2.1</strong> output ICC profile for all processed images. When used in a page layout software for print design the appropriate profiles are applied in the <strong>high-res PDF</strong> creation process.</p>),
      question: (<p>Does <app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak> support print?</p>)
    },
    {
      name: "questionAnswer07",
      open: openOnLoad,
      answer: (<><p>We have a robust API for connectivity into any other systems, portals, or services.</p><p>All registered users have full access to API documentation, installation packages and ready to use examples built-in inside your account.</p><p>API usage examples on your account are shown in a different programming languages like: Java Script, PHP, Python, Ruby, Java, C# and with additional generic example.</p><p>In general provided API is very simple and powerful because of that – it is just sending and receiving images to the processing engine gateway.</p></>),
      question: (<p>What are the system integration options?</p>)
    },
    {
      name: "questionAnswer02",
      open: openOnLoad,
      answer: (<p>
        Please reach out to us by e-mail: <br/>
        Sales, financial, offers - <CompanySalesMail color={'green'} /><br/>
        Technical support - <CompanySupportMail color={'green'} /><br/>
        Other - <CompanyContactMail color={'green'} />
      </p>),
      question: (<p>What if I have other question?</p>)
    },
  ];

  return <QuestionsAnswers
      sourceItems={sourceItems}
      translationsObject={'pages.UserGuide.questionsAnswers'}
      color={'green'}
  />;
};

export default FaqOptiplus;

